.sign-in {
	flex-direction: column;
	margin: 120px auto 192px auto;
	width: 409px;
}

.sign-in h1 {
	margin-bottom: 48px;
	align-self: start;
}

.sign-in p {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #7a7a7a;
	margin: 0px 40px 48px 24px;
}

.custom-container {
	width: 401.54px;
	height: 244.54px;
	background: #fbfbfb;
	box-shadow: 0px 1px 4px #d0dce4;
	border-radius: 2px;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}

.signIn-success-heading {
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;
	padding-top: 24px;
}

.signIn-success-content-wrapper {
	margin: auto 16px auto 24px;
}

.signIn-success-content-wrapper p {
	margin-bottom: 32px;
}

.otp-container {
	margin-bottom: 64px;
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
}
.otp-wrapper {
	width: 361px;
	height: 54.9px;
	border: 0.800654px solid #d3d3d3;
	border-radius: 4.57516px;
	justify-content: space-between;
	padding: 0px 16px;
	margin-bottom: 6px;
}

.otp {
	width: 15px;
	height: 27px;
	border: 0.800654px solid #d3d3d3;
	border-radius: 4.57516px;
}

.resend-otp {
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0.285948px;
}

#provide-otp {
	margin-bottom: 6px;
}

#resend-otp {
	color: var(--greenbanker-green);
}

/* Delete later */

.title {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	letter-spacing: -0.02em;
	color: #933d0c;
	/* padding-top: 24px; */
}

.text {
	font-family: "DM Sans";
	font-style: normal;
	font-size: 16px;
	line-height: 27px;
	color: black;
}

.btn {
	height: 48px;
	font-family: "DM Sans";
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	border-radius: 8px;
	padding: 12px 24px;
}

.btn-green-filled {
	background: #4f7c06;
	color: #fff;
	border: none;
	width: 361px;
}

.btn-grey-filled {
	background: #d3d3d3;
	color: #fff;
	border: none;
	width: 361px;
}

.btn-green-outline {
	background: rgba(0, 0, 0, 0);
	color: #4f7c06;
	border: 1.5px solid #4f7c06;
}
