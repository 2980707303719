.calendar {
  background-color: #fafafa;
  color: #4f7c06;
  padding: 20px;
  box-shadow: 0px 0.3px 1px 0px rgba(0, 0, 0, 0.3),
    0px 1.3px 12.6px 1px rgba(0, 0, 0, 0.15);
  position: relative;
  left: -40%;
}

.calendar-inputs {
  width: 110px;
  margin-right: 8px;
  text-align: center;
  border-radius: 3.826px;
  border: 0.67px solid #d3d3d3;
  padding: 8px;
}

/* react-datepicker__day react-datepicker__day--011 react-datepicker__day--keyboard-selected react-datepicker__day--in-selecting-range react-datepicker__day--selecting-range-start react-datepicker__day--selecting-range-end */

.react-datepicker__header {
  background-color: #fffaf6 !important;
}

.react-datepicker__current-month {
  color: #4f7c06 !important;
}

.react-datepicker__day-name {
  color: #6e7781;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--keyboard-selected:hover {
  background-color: #4f7c06;
  color: white;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: #4f7c06;
  color: white;
}
