.input-field span {
	position: absolute;
	margin-left: 330px;
	margin-top: 13px;
}

.input {
	width: 360px;
	height: 48px;
	border: 1px solid #d3d3d3;
	border-radius: 6px;
	padding: 12px 16px;
}

.input:focus {
	outline: none;
}

.label {
	margin-bottom: 8px;
	color: #292929;
	font-family: "DM Sans";
}

.custom-dropdown-options {
	border: 0.5px solid #d3d3d3;
	border-top: none;
	border-radius: 2px;
	border-bottom-right-radius: 6px;
	border-bottom-left-radius: 6px;
	width: 360px;
	color: #8b8b8b;
	cursor: default;
}

.custom-dropdown-options-item {
	padding: 10px;
	border-bottom: 0.5px solid #d3d3d3;
}

.custom-dropdown-options-item:last-child {
	border-bottom: none;
}

.custom-dropdown-selected {
	background-color: #f8ffee;
	padding: 9px 0px 11px 10px;
	border: 0.5px solid #b3c595;
	border-radius: 3px;
	font-weight: 500;
	color: #292929;
}
