* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  background-color: rgba(255, 255, 255, 0.9);
}

/* .layout {
	display: flex;
	flex-direction: column;
	background-color: #fafafa;
} */

* {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b9babe;

    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
