/* button styles in index.css file */

/* .green-outline {
  width: 103px;
  height: 48px;
  border: 1.5px solid #deefc2;
  border-radius: 8px;
  padding: 12px 24px;
  color: var(--greenbanker-green);
  background-color: white;
  font-family: 'DM Sans';
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
} */

.spinner {
	animation: spin infinite 1s linear;
	margin-top: 3px;

	/*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
